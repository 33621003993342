<template>
  <div class="register_box">
    <div class="black">
        <img @click="goblack" src="../../../../src/assets/image/black.png" alt="">
    </div>
    <div class="title">
        <div class="text1">找回密码</div>
        <div class="text2">输入手机号验证后找回</div>
    </div>
    <!-- 注册框 -->
    <div class="login_pop">
        <div class="account_div">
            <input class="account" type="number" v-model="params.account" placeholder="请输入手机号">
        </div>
        <div class="code_div">
            <input class="codeNum" type="text" placeholder="请输入图片验证码" v-model="imgCodeVal">
            <span @click="getCodeImg" v-if="!imgCode">获取图片验证码</span>
            <img @click="getCodeImg" v-else :src="imgCode" alt="">
        </div>
        <div class="code_div">
            <input class="codeNum" type="text" placeholder="请输入短信验证码" v-model="params.smsCode">
            <span @click="getCodeSms" v-if="!isSendSms">{{smsText}}</span>
            <span v-else>{{time}}s后可重新发送</span>
        </div>
    </div>
    <div class="login_pop">
        <div class="password_div">
            <input class="password" type="password" placeholder="请输入密码" v-model="params.password">
        </div>
        <div class="password_div">
            <input class="password" type="password" placeholder="请确认密码" v-model="twicePassword">
        </div>
    </div>
    <div class="last_but">
        <div class="login_but" @click="getBackPassword">确认找回</div>
    </div>
    <bottomList></bottomList>
  </div>
</template>

<script>
import { doforget, getImgCode, SendSms } from '@/api/user' // 找回密码 图片验证码 短信验证码
export default {
  name: "backPassword",

  data() {
    return {
        time: 60,
        timerhs: null, // 定时器
        params: {
            account: "",
            smsCode: "",
            password: ""
        },
        imgCodeVal: '', // 图片验证码
        imgCode: '', // 图片验证码url
        imgCodeId: 0, // 图片验证码的id
        h:'',
        w: '',
        twicePassword: '', // 确认密码
        isSendSms: false, // 是否发送短信
        smsText: '获取验证码',
    };
  },

  mounted() {},

  methods: {
    // 返回上一页
    goblack() {
        // 返回上一页
       this.$router.go(-1);
    },
    // 获取图片验证码
    getCodeImg() {
        getImgCode().then(res => {
            this.imgCode = res.data.image
            this.imgCodeId=  res.data.id
            this.w = res.data.w
            this.h = res.data.h
        })
    },
    // 获取短信验证码
    getCodeSms() {
        var that = this
        const reg = /^1[3-9]{1}[0-9]{9}$/
        if (!reg.test(that.params.account)) {
            return that.$layer.quicktips('请输入正确的手机号')
        }
        var data = {
            mobile: that.params.account,
            behavior: 2, // 1注册 2忘记密码
            id: that.imgCodeId,
            code: that.imgCodeVal
        }
        SendSms(data).then(res => {
            if (res.state == 200) {
                that.$layer.quicktips('短信已发送请注意查收~')
                that.isSendSms = true
                that.timerhs = setInterval(() => {
                    that.time --
                    if (that.time == 1) {
                        clearInterval(that.timerhs)
                        that.smsText = '重新发送'
                        that.isSendSms = false
                        that.time = 60
                    }
                }, 1000)
            }
        })
    },
    // 确认找回
    getBackPassword() {
        var that = this
        const reg = /^1[3-9]{1}[0-9]{9}$/
        if (!reg.test(that.params.account)) {
            return that.$layer.quicktips('请输入正确的手机号')
        }
        if (!that.params.smsCode) {
            return that.$layer.quicktips('请输入短信验证码')
        }
        if (!that.params.password) {
            return that.$layer.quicktips('请输入密码')
        }
        if (that.params.password !== that.twicePassword) {
            return that.$layer.quicktips('二次输入密码不一样')
        }
        doforget(that.params).then(res => {
            if (res.state == 200) {
                that.$layer.quicktips('密码已找回')
                that.$router.push('./index')
            }
        })
    },
  },
};
</script>

<style lang="less" scoped>
.register_box{
    width: 100vw;
    height: 100vh;
    background: #fff;
}
.black{
    position: relative;
    text-align: center;
    padding: 14rem 0rem;
    background: #fff;
    img{
        position: absolute;
        left: 14rem;
        width: 17rem;
        height: 17rem;
    }
}
.title{
    padding: 30rem 32rem;
    .text1{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 24rem;
        color: #000000;
    }
    .text2{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14rem;
        color: #BEC2D1;
        margin-top: 2rem;
    }
}
.login_pop{
    padding: 30rem 32rem;
    input{
        border: none;
        font-family: DINPro, DINPro;
        font-weight: 500;
        font-size: 16rem;
        color: #333333;
    }
    .account{
        padding: 10rem 0rem;
    }
    .password{
        padding: 10rem 0rem;
    }
}
.account_div{
    border-bottom: 1rem solid #F5F6FB;
}
.password_div{
    border-bottom: 1rem solid #F5F6FB;
}
.code_div{
    padding: 20rem 0rem 10rem 0rem;
    border-bottom: 1rem solid #F5F6FB;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 16rem;
        color: #D1B4A8;
    }
}
.last_but{
    margin-top: 40rem;
    padding: 0rem 33rem;
    .login_but{
        height: 49rem;
        background: #E0BFB1;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 15rem;
        color: #333333;
        text-align: center;
        line-height: 49rem;
    }
    .dx_but{
        height: 49rem;
        background: #F2F3FC;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 15rem;
        color: #333333;
        text-align: center;
        line-height: 49rem;
        margin-top: 12rem;
    }
    .checkbox{
        margin-top: 12rem;
        tt{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 11rem;
            color: #8D9399;
        }
        span{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 11rem;
            color: #CBAA9C;
        }
    }
}
</style>
